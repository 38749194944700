






























































































































































































import { defineComponent } from '@vue/composition-api';
import ERPRepository from '@/repositories/ERPRepository';
import PaymentRemittanceRepository from '@/repositories/PaymentRemittanceRepository';
import AccountPayableRepository from '@/repositories/AccountPayableRepository';
import DialogHelper from '@/helpers/DialogHelper';
import InputRulesHelper from '@/helpers/InputRulesHelper';
import PredictedFlowHelper from '@/views/cashFlow/predictedFlow/helpers/PredictedFlowHelper';
import PredictedFlowActionsEnum from '@/views/cashFlow/predictedFlow/enums/PredictedFlowActionsEnum';
import CashFlowTablesEnum from '@/views/cashFlowGroupers/mainGrouper/enums/CashFlowTablesEnum';
import PaymentMethodType from '@/domain/enums/PaymentMethodType';
import ERPEventsEnum from '@/domain/enums/ERPEventsEnum';
import { VForm } from '@/types/VForm';
import CompanyGroupConfig from '@/domain/models/CompanyGroupConfig';
import IPredictedFlowGrouperList from '@/views/cashFlow/predictedFlow/interfaces/groupers/IPredictedFlowGrouperList';
import IPredictedFlowGrouperItemData from '@/views/cashFlow/predictedFlow/interfaces/groupers/IPredictedFlowGrouperItemData';
import ISelectOptions from '@/domain/interfaces/ISelectOptions';
import IChangeBankDataExternalERPRequest from '@/views/cashFlow/predictedFlow/interfaces/actions/IChangeBankDataExternalERPRequest';
import SuppliersRepository from '@/repositories/SuppliersRepository';

export default defineComponent({
  name: 'UpdateBankDataDialog',
  props: {
    value: {
      required: true,
      type: Boolean,
    },
    companyGroupId: {
      required: true,
      type: Number,
    },
    register: {
      required: false,
      type: Object! as () => IPredictedFlowGrouperList,
    },
  },
  computed: {
    dialogWidth(): string {
      return DialogHelper.getDialogWidth(this.$vuetify.breakpoint, {
        smAndDown: '100%',
        md: '60%',
        default: '40%',
      });
    },
    tableName(): string {
      return PredictedFlowHelper.getTableBasedOnNcc(
        this.register!,
        Boolean(this.$session.get('use_ncc')),
      );
    },
    itemData(): IPredictedFlowGrouperItemData {
      return this.register!.itemData!;
    },
    grouperType(): CashFlowTablesEnum {
      return this.register!.grouperType;
    },
  },
  watch: {
    value(): void {
      this.isOpen = this.value;
    },
  },
  data() {
    const inputRules: InputRulesHelper = new InputRulesHelper();
    const erpRepository: ERPRepository = new ERPRepository();
    const paymentRemittanceRepository = new PaymentRemittanceRepository();
    const accountPayableRepository = new AccountPayableRepository();
    const suppliersRepository = new SuppliersRepository();

    const isOther: boolean = false;
    const loading: boolean = false;
    const isOpen: boolean = this.value;
    const isLoadingData: boolean = true;

    const accountTypes: Array<ISelectOptions<string>> = [
      {
        text: 'Conta Corrente',
        value: '1',
      },
      {
        text: 'Conta Poupança',
        value: '2',
      },
    ];

    const groupConfig = {} as CompanyGroupConfig;
    const data: Record<string, string> = {
      supplier_account_digit: '',
      supplier_account_number: '',
      supplier_account_type: '',
      supplier_agency: '',
      supplier_agency_digit: '',
      supplier_bank: '',
      supplier_z_document: '',
      supplier_z_name: '',
      type: '',
    };

    return {
      inputRules,
      erpRepository,
      data,
      loading,
      isOpen,
      isLoadingData,
      paymentRemittanceRepository,
      groupConfig,
      accountTypes,
      accountPayableRepository,
      isOther,
      suppliersRepository,
    };
  },
  async created() {
    this.isLoadingData = true;
    this.groupConfig = await this.paymentRemittanceRepository.getGroupConfig(
      this.companyGroupId,
    );

    await this.defineIfIsOtherPaymentType();
    await this.loadSupplierData();
    this.isLoadingData = false;
    this.isOpen = this.value;
  },
  methods: {
    async updateBankData(): Promise<void> {
      try {
        this.loading = true;

        const requests = [this.updateInternalERPValue()];

        if (this.grouperType !== CashFlowTablesEnum.BANKS) {
          requests.unshift(this.updateExternalERPValue());
        }

        const response = await Promise.allSettled(requests);

        if (!response.every(({ status }) => status === 'fulfilled')) {
          throw new Error();
        }

        this.$notification.success('Dados bancários alterado com sucesso!');
      } catch (error) {
        this.$notification.error('Houve um problema ao alterar os dados bancários.');
      } finally {
        this.loading = false;
      }
    },
    async updateExternalERPValue(): Promise<void> {
      const formatData = {
        type: this.tableName === 'account_payable' ? 'provider' : 'customer',
        bank: this.data.supplier_bank,
        agency: this.data.supplier_agency,
        account_number: this.data.supplier_account_number,
        account_type: this.data.supplier_account_type,
      } as IChangeBankDataExternalERPRequest;

      if (this.groupConfig.use_digag) {
        formatData.agency_digit = this.data.supplier_agency_digit;
      }

      if (this.groupConfig.use_digcta) {
        formatData.account_digit = this.data.supplier_account_digit;
      }

      if (this.data.supplier_z_document && this.data.supplier_z_name) {
        formatData.general_code = this.justNumbers(
          this.data.supplier_z_document,
        );
        formatData.provider_name = this.data.supplier_z_name;
      }

      const hasPayerData = Object.prototype.hasOwnProperty.call(formatData, 'general_code')
        && Object.prototype.hasOwnProperty.call(formatData, 'provider_name');

      const event = hasPayerData
        ? ERPEventsEnum.BANK_DATA_WITH_DIFFERENT_HOLDER
        : ERPEventsEnum.BANK_DATA;

      const params = {
        id: this.itemData.customerIdCustomer,
        data: formatData as IChangeBankDataExternalERPRequest,
        event,
        type: this.tableName === 'account_payable' ? 'provider' : 'customer',
      };

      await this.erpRepository.updateExternalERP<IChangeBankDataExternalERPRequest>(
        this.companyGroupId,
        this.itemData.supplierCompanyId!,
        {
          id: params.id,
          data: params.data,
          event,
        },
      );
    },
    async updateInternalERPValue(): Promise<void> {
      const supplierFields = [
        {
          field: 'A2_BANCO',
          value: this.data.supplier_bank,
        },
        {
          field: 'A2_AGENCIA',
          value: this.data.supplier_agency,
        },
        {
          field: 'A2_NUMCON',
          value: this.data.supplier_account_number,
        },
        {
          field: 'A2_TIPCTA',
          value: this.data.supplier_account_type,
        },
      ];

      if (this.groupConfig.use_digag) {
        supplierFields.push({
          field: 'A2_DVAGE',
          value: this.data.supplier_agency_digit,
        });
      }

      if (this.groupConfig.use_digcta) {
        supplierFields.push({
          field: 'A2_DVCTA',
          value: this.data.supplier_account_digit,
        });
      }

      if (this.data.supplier_z_document && this.data.supplier_z_name) {
        supplierFields.push(
          {
            field:
              this.tableName === 'account_receivable' ? 'A1_ZCGC' : 'A2_ZCGC',
            value: this.justNumbers(this.data.supplier_z_document),
          },
          {
            field:
              this.tableName === 'account_receivable' ? 'A1_ZNOME' : 'A2_ZNOME',
            value: this.data.supplier_z_name,
          },
        );
      }

      await Promise.all(
        supplierFields.map((field) => this.erpRepository.updateInternalERP({
            id: this.itemData.supplierId!,
            identify_values: 'fornecedor',
            field: field.field,
            value: field.value,
          })),
      );
    },
    close(): void {
      this.$emit('close', PredictedFlowActionsEnum.update_bank_data);
    },
    async defineIfIsOtherPaymentType() {
      let account = null;

      if (this.register!.grouperType === 'accounts_payable') {
        account = await this.accountPayableRepository.find(
          this.register!.itemData?.idCustomer as string,
          this.companyGroupId,
          this.register!.itemData?.companyId as number,
        );

        this.isOther = account.form === PaymentMethodType.TED_OTHER
          || account.form === PaymentMethodType.DOC_OTHER;
      }
    },
    justNumbers(value: string): string {
      return value.replace(/[^\d]/g, '');
    },
    handleChangeBankData(): void {
      const updateBankDataForm = this.$refs.UpdateBankDataForm as VForm;

      if (!updateBankDataForm.validate()) {
        this.$notification.error('Há campos preenchidos incorretamente!');
        return;
      }

      this.updateBankData();
    },
    async loadSupplierData(): Promise<void> {
      try {
        const supplier = await this.suppliersRepository.findSupplierByCustomerId(
          this.companyGroupId,
          this.itemData.customerIdCustomer,
        );

        Object.assign(this.data, {
          supplier_bank: supplier.supplier_bank || '',
          supplier_agency: supplier.supplier_agency || '',
          supplier_agency_digit: supplier.supplier_agency_digit || '',
          supplier_account_number: supplier.supplier_account_number || '',
          supplier_account_digit: supplier.supplier_account_digit || '',
          supplier_account_type: supplier.supplier_account_type || '',
          supplier_z_document: supplier.supplier_z_document || '',
          supplier_z_name: supplier.supplier_z_name || '',
        });
      } catch (error) {
        console.error('Erro ao carregar dados do fornecedor:', error);
        this.$notification.error('Erro ao carregar dados do fornecedor');
      }
    },
  },
});

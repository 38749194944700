import Supplier from '@/domain/models/Supplier';
import Repository from '@/repositories/base/Repository';

export default class SuppliersRepository extends Repository {
  public async findSupplierByCustomerId(groupId: number, customerId: string): Promise<any> {
    const { status, data: response } = await this.datasource.get(`company-groups/${groupId}/suppliers/search/${customerId}`);

    if (this.isStatusOK(status)) {
      return Supplier.make(response.data[0]);
    }

    throw new Error('Ops, algo inesperado ocorreu durante a busca do fornecedor!');
  }
}

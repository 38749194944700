




































































































































































































import {
  Vue,
  Component,
  Prop,
  Emit,
  Watch,
} from 'vue-property-decorator';

import { DataOptions } from 'vuetify';

import IVDataTableHeader from '@/types/IVDataTableHeader';
import DDAConciliation from '@/domain/models/DDAConciliation';
import DDAConciliationMovement from '@/domain/models/DDAConciliationMovement';
import IDDAConciliationFilter from '@/domain/interfaces/IDDAConciliationFilter';

import StatusType from '@/domain/enums/DDAConciliationStatusType';
import OriginType from '@/domain/enums/DDAConciliationOriginType';
import ActionType from '@/domain/enums/DDAConciliationActionType';

import { formateDate } from '@/utils/date';
import { ActionData, IConciliation } from '@/views/ddaConciliation/utils/interfaces';
import DDAConciliationRepository from '@/repositories/DDAConciliationRepository';

import ListStatus from './ListStatus.vue';
import ListAction from './ListAction.vue';

import DdaConciliationUndo from './Undo.vue';
import DdaConciliationBarcode from './Barcode.vue';
import DdaConciliationResolve from './Resolve.vue';
import DdaConciliationInvoice from './Invoice.vue';
import DdaConciliationInvoiceView from './InvoiceView.vue';
import DdaConciliationSearchErp from './SearchERP.vue';
import DdaConciliationSearchDda from './SearchDDA.vue';
import DdaConciliationRemoveDda from './RemoveDDA.vue';
import DdaConciliationGenerateInvoiceDialog from './DdaConciliationGenerateInvoiceDialog.vue';
import {
  formateCNPJ,
  formateCPF,
  formateCurrency,
  formateErrorForNotification,
} from '../utils';

@Component({
  components: {
    ListStatus,
    ListAction,
    DdaConciliationUndo,
    DdaConciliationBarcode,
    DdaConciliationResolve,
    DdaConciliationInvoice,
    DdaConciliationInvoiceView,
    DdaConciliationSearchErp,
    DdaConciliationSearchDda,
    DdaConciliationRemoveDda,
    DdaConciliationGenerateInvoiceDialog,
  },
})
export default class DDAConciliationList extends Vue {
  @Prop({
    type: Array as () => Array<DDAConciliation>,
  }) readonly items!: Array<DDAConciliation>;

  @Prop({
    type: Object as () => IDDAConciliationFilter,
  }) readonly data!: IDDAConciliationFilter;

  @Prop(String) readonly sortByValue!: string;
  @Prop(Boolean) readonly sortDescValue!: boolean;

  @Watch('options')
  changedOptions(newValue: DataOptions, oldValue: DataOptions) {
    const { sortBy: sortByNew, sortDesc: sortDescNew } = newValue;
    const { sortBy: sortByOld, sortDesc: sortDescOld } = oldValue;
    if (!(sortByNew === sortByOld && sortDescNew === sortDescOld)) this.updateSort();
  }

  @Emit()
  updateSort(): DataOptions {
    return this.options;
  }

  @Emit()
  reload(): boolean {
    return true;
  }

  readonly DDAConciliationRepository:
    DDAConciliationRepository = new DDAConciliationRepository();

  readonly formateDate = formateDate;
  readonly formateCurrency = formateCurrency;

  isStatementConciliationGenerateAdvanceDialogOpen: boolean = false;
  createMovementData: DDAConciliationMovement = {} as DDAConciliationMovement;

  openUndo: boolean = false;
  openBarcode: boolean = false;
  openResolve: boolean = false;
  openInvoice: boolean = false;
  openInvoiceView: boolean = false;
  openSearchERP: boolean = false;
  openSearchDDA: boolean = false;
  openRemoveDDA: boolean = false;

  registerBarcode: boolean = false;

  itemUndo: DDAConciliation = {} as DDAConciliation;
  itemResolve: DDAConciliation = {} as DDAConciliation;
  itemInvoice: DDAConciliation = {} as DDAConciliation;
  itemInvoiceView: DDAConciliationMovement = {} as DDAConciliationMovement;
  itemSearchERP: DDAConciliation = {} as DDAConciliation;
  itemSearchDDA: DDAConciliation = {} as DDAConciliation;
  itemRemoveDDA: DDAConciliation = {} as DDAConciliation;

  itemBarcode: DDAConciliationMovement = {} as DDAConciliationMovement;

  get actionsPermission(): number {
    return Number(this.$session.get('user_access-dda_conciliation_actions'));
  }

  get hasPermissionToGenerateAccountPayable(): number {
    return Number(this.$session.get('user_access-generate_dda_account_payable'));
  }

  options: DataOptions = {
    ...{} as DataOptions,
    sortBy: [this.sortByValue],
    sortDesc: [this.sortDescValue],
  };

  headers: Array<IVDataTableHeader> = [
    {
      text: 'Status',
      value: 'status',
    },
    {
      text: 'Origem',
      value: 'origin',
    },
    {
      text: 'CNPJ',
      value: 'document',
      sortable: true,
    },
    {
      text: 'Razão Social',
      value: 'name',
      sortable: true,
    },
    {
      text: 'Título/Parc.',
      value: 'title',
    },
    {
      text: 'Valor',
      value: 'value',
      sortable: true,
    },
    {
      text: 'Emissão',
      value: 'emission_date',
      sortable: true,
    },
    {
      text: 'Vencimento',
      value: 'due_date',
      sortable: true,
    },
    {
      text: 'Cód. Barras',
      value: 'barcode',
    },
    {
      text: 'Ações',
      value: 'action',
    },
  ];

  showBarcode(movement: DDAConciliationMovement): void {
    this.openBarcode = true;
    this.itemBarcode = movement;
  }

  hasRadioBox(status: StatusType): boolean {
    return (
      status === StatusType.DIVERGENCE
      || status === StatusType.ERP_WITH_BARCODE
    );
  }

  checkOtherMovementsRadio(
    movements: Array<DDAConciliationMovement>, indexMovement: number,
  ) {
    movements.forEach((movement, index) => {
      if (movement.origin === OriginType.ERP && index != indexMovement) {
        movement.selected = false;
      }
    });
  }

  sameBarcode(items: Array<DDAConciliationMovement>): boolean {
    const { barcode } = items[0];
    return items.every((item) => item.barcode === barcode);
  }

  someSelectedToResolve(item: DDAConciliation): boolean {
    return item.movements
      .some((mov: DDAConciliationMovement) => mov.origin === OriginType.ERP && mov.selected);
  }

  action(data: ActionData): void {
    const { type, item } = data;

    switch (type) {
      case ActionType.SEARCH_ERP:
        this.itemSearchERP = item;
        this.openSearchERP = true;
        break;
      case ActionType.GENERATE_INVOICE: {
        this.prepareMovementDialogValues(ActionType.GENERATE_INVOICE, item);
        break;
      }
      case ActionType.SEARCH_DDA:
        this.itemSearchDDA = item;
        this.openSearchDDA = true;
        break;
      case ActionType.INVOICE:
        this.itemInvoice = item;
        this.openInvoice = true;
        break;
      case ActionType.VIEW_INVOICE: {
        const invoice = item.movements
          .find((mov) => mov.origin === OriginType.INN);

        if (invoice) {
          this.itemInvoiceView = invoice;
          this.openInvoiceView = true;
        } else {
          this.$notification
            .warn('Nenhum fatura encontrada!');
        }

        break;
      }
      case ActionType.RESOLVE:
        if (this.someSelectedToResolve(item)) {
          this.itemResolve = item;
          this.openResolve = true;
        } else {
          this.$notification
            .warn('É necessário selecionar um ERP para conciliar!');
        }
        break;
      case ActionType.BARCODE: {
        const [movement] = data.item.movements;
        this.itemBarcode = movement;
        this.registerBarcode = true;
        this.openBarcode = true;
        break;
      }
      case ActionType.UNDO:
        this.itemUndo = item;
        this.openUndo = true;
        break;
      case ActionType.REMOVE_DDA:
        this.itemRemoveDDA = item;
        this.openRemoveDDA = true;
        break;
      default:
        this.$notification.warn('Ação inválida!');
        break;
    }
  }

  close(dialog: ActionType): void {
    switch (dialog) {
      case ActionType.SEARCH_ERP:
        this.itemSearchERP = {} as DDAConciliation;
        this.openSearchERP = false;
        break;
      case ActionType.SEARCH_DDA:
        this.itemSearchDDA = {} as DDAConciliation;
        this.openSearchDDA = false;
        break;
      case ActionType.INVOICE:
        this.itemInvoice = {} as DDAConciliation;
        this.openInvoice = false;
        break;
      case ActionType.VIEW_INVOICE:
        this.itemInvoiceView = {} as DDAConciliationMovement;
        this.openInvoiceView = false;
        break;
      case ActionType.RESOLVE:
        // this.itemResolve = {} as DDAConciliation;
        this.openResolve = false;
        break;
      case ActionType.BARCODE:
        this.itemBarcode = {} as DDAConciliationMovement;
        this.registerBarcode = false;
        this.openBarcode = false;
        break;
      case ActionType.UNDO:
        this.itemUndo = {} as DDAConciliation;
        this.openUndo = false;
        break;
      case ActionType.GENERATE_INVOICE:
        this.isStatementConciliationGenerateAdvanceDialogOpen = false;
        break;
      case ActionType.REMOVE_DDA:
        this.itemRemoveDDA = {} as DDAConciliation;
        this.openRemoveDDA = false;
        break;
      default:
        this.openSearchERP = false;
        this.openSearchDDA = false;
        this.openInvoice = false;
        this.openResolve = false;
        this.openBarcode = false;
        this.openUndo = false;
        this.openRemoveDDA = false;
        break;
    }
  }

  formattingDocument(document: string): string {
    if (!document) {
      return '';
    }

    if (document && (document.startsWith('000') && document.substring(3).length === 11)) {
      return formateCPF(document.replace(/^000/, ''));
    }

    if (document.length === 11) {
      return formateCPF(document);
    }

    return formateCNPJ(document);
  }

  public prepareMovementDialogValues(
    movementAction: ActionType.GENERATE_INVOICE,
    selectItem: DDAConciliation,
  ): void {
    const correlationBetweenMovementActionAndDialogTrigger = {
      create: 'openCreateMovement',
      'generate-invoice': 'isStatementConciliationGenerateAdvanceDialogOpen',
    } as const;
      this.createMovementData = {
       ...selectItem.movements[0],
      };

      this[correlationBetweenMovementActionAndDialogTrigger[movementAction]] = true;
  }

  async conciliationResolve(resolve?: { hasDivergence: boolean, dueDate: string | undefined }) {
    try {
      this.$dialog.startLoading();

      const group = this.$session.get('company_group_id');

      const dda = this.itemResolve.movements
        .find((mov) => mov.origin == OriginType.DDA) || {} as DDAConciliationMovement;
      const erp = this.itemResolve.movements
        .find((mov) => mov.origin == OriginType.ERP && mov.selected)
        || {} as DDAConciliationMovement;

      const data: IConciliation = {
        id: +dda.id,
        id_customer: `${erp.id}`,
        has_divergence: resolve?.hasDivergence ?? false,
        due_date: resolve?.dueDate ?? undefined,
      };

      const ddaCompany = dda.company_id;

      const success = await this.DDAConciliationRepository.conciliation(group, ddaCompany, data);

      if (success) {
        this.$notification.success('Conciliação gerada com sucesso!');
        this.reload();
      }
    } catch (error: any) {
      this.$dialog.stopLoading();
      const message = formateErrorForNotification(error);
      this.$notification.error(message);
    }
  }
}
